:root {
  --background-header: #3D3E43;
  --color-text-item-menu: #A5A5A5;
  --color-text-item-submenu: #000;
  --border-radius80: 3vw;
  --border-radius40: 2vw;
  --font-family: "Montserrat", sans-serif;
  --background-color:#F8F8F8;
  --color-primary:#217C4C;
  --color-secondary: #98BD1E;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

::selection {
  background-color: var(--color-secondary);
  color: #fff;
}
::-moz-selection {
  background-color: var(--color-secondary);
  color: #fff;
}

body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background-color);
}

body {
  scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */
body::-webkit-scrollbar {
  width: 16px;
}

body::-webkit-scrollbar-track {
  background: var(--background-header);
}

body::-webkit-scrollbar-thumb {
  background-color: #e4e4e4;

  border-radius: 20px;
  border: 4px solid var(--background-header);
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #cacaca;
}

body::-webkit-scrollbar-thumb:active {
  background-color: #aaaaaa;
}

img {
  user-select: none;
}