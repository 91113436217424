.whatsapp-btn {
  position: fixed;
  z-index: 9999;
  right: 25px;
  bottom: 100px;
  background-color: #A0DC00;
  border-radius: 50%;
  padding: 5px;
  width: 60px;
  height: 60px;
  text-align: center;
  color: white;
  transition: ease-in-out .5s;

  box-shadow: 1px 4px 9px 4px rgba(0,0,0,0.15);
  -webkit-box-shadow: 1px 4px 9px 4px rgba(0,0,0,0.15);
  -moz-box-shadow: 1px 4px 9px 4px rgba(0,0,0,0.15);
}

.Buttons a {
  font-size: 2.5rem !important;
}
.whatsapp-btn:hover {
  color: #A0DC00;
  background-color: #fff;
}

/* @media screen and (max-width: 390px) {
  .whatsapp-btn {
    width: 45px;
    height: 45px;
    padding: 4px;
  }  
  .Buttons a {
    font-size: 2rem !important;
  }
} */
