.politicas:first-child {
    margin-top: 78px;
}

.politicas {
    margin-bottom: 30px;
    text-align: left;
}

.contenedor-empresa__politicas__enunciado__titulo {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* or 150% */


    color: #000000;
    margin-bottom: 66px;
}