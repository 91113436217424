.form-contacto-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 80%;
  margin: auto;
  height: 45rem;
  clip-path: inset(-100px -100px 0px -15px);
}

.form-contacto {
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
  background-color: white;
  border-top-left-radius: 5rem;
  padding: 5rem 2rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10rem;
}

.form-header {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-title {
  color: var(--color-primary);
  font-size: 2rem;
  font-weight: 600;
}

.form-info-container {
  display: flex;
  gap: 7px;
}

.form-info {
  color: var(--color-secondary);
  font-size: 1.25rem;
}

.form-info:nth-child(2n) {
  color: var(--color-primary);
  font-weight: 600;
}

.input-container-contacto {
  position: relative;
  height: 3.5rem;
  margin-top: 0;
}

.input {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 80%;
  width: 100%;
  border: 1px solid var(--color-primary);
  border-radius: 0.5rem;
  font-size: 1rem;
  color: var(--color-primary);
  padding: 0 2rem;
  outline: none;
  background: none;
  z-index: 1;
}

.contenedor-label-contacto {
  margin-bottom: 3px;
}

::placeholder {
  color: transparent;
}

.label {
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 0 0.4rem;
  background-color: transparent;
  color: var(--color-primary);
  font-size: 0.8rem;
  transition: 0.5s;
  z-index: 0;
}

.input-error {
  color: red;
  font-size: .75rem;
  position: relative;
  top: 0px;
}

.input-error-consulta {
  color: red;
  font-size: .75rem;
  position: relative;
  top: -20px;
}

.input:focus+.label {
  top: -7px;
  left: 3px;
  z-index: 10;
  font-size: 0.8rem;
  font-weight: 600;
  color: #fff;
  background-color: var(--color-primary);
  border-radius: 5px;
}

.input:focus {
  border: 2px solid var(--color-primary);
}

.input:not(:placeholder-shown)+.label {
  top: -7px;
  left: 3px;
  z-index: 10;
  font-size: 0.8rem;
  font-weight: 600;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset;
}

.grid-container {
  display: grid;
  grid-template-columns: 30% 67%;
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
  justify-content: space-between;
}

.input-consulta {
  position: relative;
  height: 110px;
}

textarea {
  resize: none;
  padding-top: 2rem;
  padding: 2rem !important;
}

.btnSubmit {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-top: 1rem;
}

.btnModal {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-top: 2rem;
}


.submitBtn {
  background: linear-gradient(270deg, #FF772C 1%, #FFB12C 100%);
  font-size: 1.4rem;
  text-decoration: none;
  font-weight: 400;
  outline: none;
  border: 0px solid transparent;
  border-radius: 10px;
  cursor: pointer;
  color: white;
  width: 180px;
  height: 45px;
  transition: all .5s;
  border-color: 2px white;
}

.submitBtn:hover {
  background: linear-gradient(10deg, #FF772C 1%, #FFB12C 100%);
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));
}


.submitBtnLoading {
  background: linear-gradient(270deg, #FF772C 1%, #FFB12C 100%);
  font-size: 1.4rem;
  text-decoration: none;
  font-weight: 400;
  outline: none;
  border: 0px solid transparent;
  border-radius: 10px;
  cursor: pointer;
  color: white;
  width: 180px;
  height: 45px;
  border-color: 2px white;
  animation: loadingAction 1s ease infinite;
}

.form-contacto-img {
  display: flex;
  align-items: flex-end;
  height: 100%;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
}

.form-contacto-img img {
  border-top-left-radius: 5rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form-contacto.hideFormSection {
  display: none;
}

@media screen and (max-width: 1400px) {
  .form-contacto {
    width: 70%;
  }
}

@media screen and (max-width: 1200px) {
  .form-contacto-container {
    height: 40rem;
    width: 90%;
  }

  .form-contacto {
    padding: 3rem 2rem;
    width: 80%;
  }
}

@media screen and (max-width: 1000px) {
  .form-contacto-container {
    height: auto;
  }

  .form-contacto {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    margin-top: 0;
    width: 100%;
  }

  .form-info {
    text-align: center;
  }

  .form-contacto-img {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .form-contacto-container {
    width: 100%;
  }

  .form-title {
    font-size: 1.4rem;
    text-align: center;
  }

  .form-info-container {
    flex-direction: column;
    gap: 1px;
    padding-top: 1rem;
  }

  .form-info {
    font-size: 1rem;
  }

  .form-contacto {
    padding: 1rem;
    filter: none;
  }

  .form-header {
    margin: 2rem 0 3rem;
  }

  .form-contacto-img {
    margin-right: 1rem;
  }

  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
  }

  .submitBtn {
    font-size: 1.2rem;
    width: 160px;
    height: 40px;
  }

  .submitBtn {
    width: 100%;
  }

  .submitBtnLoading {
    width: 100%;
  }
}

@media screen and (max-width:400px) {
  .btnModal {
    padding-top: 1rem;
  }
}

@keyframes loadingAction {
  0% {
    opacity: .5;
  }

  50% {
    opacity: .6;
  }

  100% {
    opacity: .5;
  }
}