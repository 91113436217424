.contenedor-contacto__header__titulo {
  background: var(--color-secondary);
  box-shadow: 0px 0px 0.7vw rgba(0, 0, 0, 0.1);
  border-radius: 0 0 5.8vw 5.8vw;
  padding: 2.4vw;
  margin: 0 18vw 5rem;
  animation: fadeInHeader .4s ease;
  text-align: center;
}

.contenedor-contacto__header__titulo__text {
  color: #fff;
  font-weight: 300;
  font-size: 2.6vw;
  line-height: 2.6vw;
}

.contenedor-contacto__header__titulo__text strong {
  font-weight: 700;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  .contenedor-contacto__header__titulo {
    padding: 4vw;
    margin: 0 5vw 0;
  }
  
  .contenedor-contacto__header__titulo__text {
    font-size: 1rem;
    line-height: unset;
    text-align: center;
  }
}

